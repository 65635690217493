<template>
  <div>
		<v-container>
			<navbar title="تماس با ما" route='/panel'/>
			<v-main >
				<contact/>
			</v-main>
		</v-container>
	</div>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import contact from './components/cards/contactUs'
export default {
	components: {
		navbar,
		contact
	}
}
</script>